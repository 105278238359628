.shepherd-element {
  border-radius: 20px;
  z-index: 1000000000;
  background: white;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 500px;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}

.shepherd-target {
  z-index: 100000000;
}

.shepherd-header {
  padding-bottom: 20px;
}

.shepherd-title {
  color: black;
  display: inline-block;
  width: 80%;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}

.shepherd-text {
  color: #4a4a4a;
  font-size: 15px;
}

.shepherd-button {
  background-color: black;
  color: white;
  font-size: 13px;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 100px;
  border: none;
  text-shadow: none;
  line-height: 1;
  cursor: pointer;
}

.shepherd-button-primary.shepherd-button {
  float: right;
}

.shepherd-footer {
  padding-top: 20px;
}

.shepherd-cancel-icon {
  float: right;
  color: white;
  border: none;
  background-color: black;
  border-radius: 100%;
  font-size: 20px;
  padding: 0px 7px 2px 7px;
  cursor: pointer;
}
